import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useLanguageContext } from "../../contexts/language-context";
import snarkdown from "snarkdown";
import { Fragment } from "react";

// Styles
import "./notifications.bootstrap.scss";

import Icon from "../custom-widgets/icon";

const NotificationBar = () => {
  const isSpanish = useLanguageContext();

  const alertStripeMessageData = useStaticQuery(graphql`
    query {
      messages: allStrapiAlertStripeMessages(
        filter: { Active: { eq: true } }
        sort: { fields: [DisplayOrder], order: ASC }
      ) {
        nodes {
          id
          Active
          BackgroundColorClass
          DisplayOrder
          MessageText
          SpanishMessageText
          StartDateTime
          EndDateTime
        }
      }
    }
  `);
  const messageNodes = alertStripeMessageData.messages.nodes;

  const [activeMessages, setActiveMessages] = useState([]);
  let [show, setShow] = useState([]);

  const getActiveMessages = function () {
    const now = Date.now();
    return messageNodes.filter((mesg) => {
      // if a message does not have a start time - consider it active.
      if (mesg.StartDateTime !== null) {
        const start = new Date(mesg.StartDateTime);
        if (now < start.getTime()) {
          // the message has not started yet - make it inactive.
          return false;
        }
      }
      // if a message does not have an end time - consider it active.
      if (mesg.EndDateTime !== null) {
        const end = new Date(mesg.EndDateTime);
        if (now > end.getTime()) {
          // the message has expired - make it inactive.
          return false;
        }
      }
      // otherwise consider the message active.
      return true;
    });
  };

  // run only once on page load
  useEffect(() => {
    const activeMsgs = getActiveMessages();
    setActiveMessages(activeMsgs);
    let INITAL_STATE = Array(activeMsgs.length).fill(true);
    setShow(INITAL_STATE);
  }, []);

  const bgClass = {
    info: "bg-blue-10",
    warning: "bg-yellow-10",
    danger: "bg-red-10",
    success: "bg-green-10",
    generic: "bg-gray-10"
  };

  const icon = {
    info: "info-circle",
    warning: "exclamation-triangle",
    danger: "info-circle",
    success: "check-circle",
    generic: "question-circle"
  };

  const iconColorClass = {
    info: "text-primary",
    warning: "text-warning",
    danger: "text-danger",
    success: "text-success",
    generic: "text-gray-60"
  };

  return (
    <div id="notification-bar">
      {activeMessages.map((mesg, index) => {
        const mesgText = isSpanish ? mesg.SpanishMessageText : mesg.MessageText;
        const messageType = mesg.BackgroundColorClass;
        return (
          <Fragment key={index}>
            {mesgText && mesgText.trim().length > 0 && show[index] && (
              <div
                key={index}
                id={`notification-message-${index + 1}`}
                style={{ minHeight: "44px" }}
                className={`${bgClass[messageType]} d-flex`}
              >
                <div className="container notification-text h5-font-size py-2 d-flex align-items-start justify-content-center">
                  <Icon class={`${iconColorClass[messageType]} mt-1 mr-3 fa-w-18`} name={icon[messageType]} lib="fas" />
                  <div dangerouslySetInnerHTML={{ __html: snarkdown(mesgText) }}></div>
                </div>
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
export default NotificationBar;
