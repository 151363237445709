import React from "react";
import { Link } from "gatsby";

import WafdData from "../../data/wafd-data";
import ServiceStatesMapLargeNew from "./service-states-map-lg-new";
import ServiceStatesMapSpanishLargeNew from "./service-state-map-spanish-lg-new";
import ServiceStatesLinks from "./service-states-links";
import { useLanguageContext } from "../../contexts/language-context";

import "./service-states.bootstrap.scss";

const ServiceStates = (props) => {
  const isSpanish = useLanguageContext();
  return (
    <div id="service-states-section" className={props.containerClass}>
      <div className="container">
        <div className="row text-white mb-1 mb-sm-2 mb-md-0">
          <div className="col-md-6">
            <h1 className="text-white h2-font-size font-weight-semi-bold">
              {isSpanish
                ? "WaFd Bank, su banco local para su banca personal y comercial"
                : "WaFd Bank, Your Local Bank for Personal & Business Banking"}
            </h1>
            <div className="row">
              <div className="col-12 mb-3">
                {isSpanish ? (
                  <ul id="service-states-list-es">
                    <li id="service-states-list-item-1">
                      WaFd Bank es un banco local y un prestamista de cartera con más de 200 sucursales en 9 estados
                      para servirle
                    </li>
                    <li id="service-states-list-item-2">Miles de cajeros automáticos SIN CARGO</li>
                    <li id="service-states-list-item-3">
                      Banca móvil y en línea las 24 horas del día, los 7 días de la semana con depósito de cheques móvil
                    </li>
                    <li id="service-states-list-item-4"> Cajero automático de Drive Up</li>
                  </ul>
                ) : (
                  <ul id="service-states-list">
                    <li id="service-states-list-item-1">
                      WaFd Bank is a local bank and{" "}
                      <Link
                        id="service-states-link-portfolio-loan"
                        className="text-white"
                        to="/blog/home-ownership/what-is-a-portfolio-loan"
                      >
                        portfolio lender
                      </Link>{" "}
                      with more than 200 branches in 9 states to serve you
                    </li>
                    <li id="service-states-list-item-2">Thousands of FEE-FREE ATMs</li>
                    <li id="service-states-list-item-3">24/7 online &amp; mobile banking with mobile check deposit</li>
                    <li id="service-states-list-item-4">Drive up ATMs</li>
                  </ul>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm mb-3">
                <Link
                  id="service-states-link-atms"
                  to={WafdData.urls.atmLocator}
                  className="btn-white btn btn-block no-max-width"
                  role="button"
                >
                  {isSpanish ? "Encuentra un cajero automático" : "Find an ATM"}
                </Link>
              </div>
              <div className="col-sm mb-3">
                <a
                  id="service-states-link-locations"
                  href={isSpanish ? "/es/sucursales" : "/locations"}
                  className="btn-white btn btn-block no-max-width"
                  role="button"
                >
                  {isSpanish ? "Encuentre una sucursal local" : "Find a local branch"}
                </a>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <ServiceStatesLinks darkBackground={true} />
              </div>
            </div>
          </div>
          <div id="service-states-map-section" className="d-none d-md-flex col-md-6 center-block align-items-start">
            {isSpanish ? <ServiceStatesMapSpanishLargeNew /> : <ServiceStatesMapLargeNew />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceStates;

ServiceStates.defaultProps = {
  containerClass: "bg-info"
};
