import React from "react";
import PropTypes from "prop-types";

// Components
import GlobalLayout from "./global-layout";
import Header from "../components/header/header";
import NotificationBar from "../components/notifications/notification-bar";
import PersonalizationMessageBar from "../components/notifications/personalization-message-bar";
import Footer from "../components/footer/footer";

const Home = ({ children, footerBorder }) => {
  return (
    <GlobalLayout>
      <NotificationBar />
      <Header />
      <PersonalizationMessageBar />
      <main id="main-content">{children}</main>
      <Footer footerBorder={footerBorder} />
    </GlobalLayout>
  );
};

Home.propTypes = {
  children: PropTypes.node.isRequired
};

export default Home;
